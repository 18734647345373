<template>
    <div class="details">
        <div class="state-title">
            <i style="color:#f00" class="iconfont icon-password"></i>
            <span>{{$t('passWordUser.name')}}</span>
        </div>
        <div class="details-form">
            <el-form :model="sendForm" :rules="rules" ref="sendForm" label-width="100px" class="demo-ruleForm">
                <el-form-item :label="$t('passWordUser.odd')" prop="pwd" style="width:60%">
                    <el-input @change="pwdChange" size="small" type="password" v-model="pwd" :placeholder="$t('passWordUser.oddtext')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('passWordUser.new')" prop="pwrd" style="width:60%">
                    <el-input size="small" v-model="pwrd" type="password" :placeholder="$t('passWordUser.newtext')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('passWordUser.newBe')" prop="password" style="width:60%">
                    <el-input @change="passChange" size="small" type="password" v-model="password" :placeholder="$t('passWordUser.newBetext')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" @click="submitForm('sendForm')">{{$t('passWordUser.cuss')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { sha256 } from 'js-sha256'
import md5 from "js-md5"
export default {
    data(){
        return{
            rules: {},
            pwd: "",
            pwrd: "",
            password: "",
            passWordShow: true
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
    },
    methods: {
        pwdChange(e){
            if(this.sendForm.passWord != sha256(md5(e))){
                this.passWordShow = false
                this.$message.error(this.$i18n.t('passWordUser.addPassWordNo'))
            }else{
                this.passWordShow = true
            }
        },
        passChange(e){
            if(this.pwrd != e){
                this.passWordShow = false
                this.$message.error(this.$i18n.t('passWordUser.twoPassWordNo'))
            }else{
                this.passWordShow = true
            }
        },
        // 提交表单
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.pwd){
                        if(this.pwrd){
                            if(this.password){
                                if(this.passWordShow){
                                    this.sendForm["type"] = 'user'
                                    this.sendForm.passWord = sha256(md5(this.password))
                                    this.$nextTick(()=>{
                                        this.$isAxios.axiosPost(this.$isApi.modifyUser,this.sendForm,(res)=>{
                                            if(res.data.result == 'OK'){
                                                sessionStorage['sendForm'] = JSON.stringify(res.data.userInfo)
                                                this.$message({
                                                    message: this.$i18n.t('userLeft.successfully'),
                                                    type: 'success'
                                                })
                                                setTimeout(()=>{
                                                    this.$router.go(0)
                                                },1000)
                                            }else{
                                                this.$message({
                                                    message: this.$i18n.t('userLeft.noSuccessfully'),
                                                    type: 'warning'
                                                })
                                            }
                                        })
                                    })
                                }else{
                                    this.$message.error(this.$i18n.t('passWordUser.textNO'))
                                }
                            }else{
                                this.$message.error(this.$i18n.t('passWordUser.newBetext'))
                            }
                        }else{
                            this.$message.error(this.$i18n.t('passWordUser.newtext'))
                        }
                    }else{
                        this.$message.error(this.$i18n.t('passWordUser.oddtext'))
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style scoped>
.details{
    background: #fff;
    height: max-content;
    padding: 20px 20px;
}
.details-form{
    margin-top: 30px;
}
.avatar{
    width: 120px;
    height: 120px;
    background: #000;
    object-fit: scale-down
}
.el-icon-plus{
    width: 100px;
    height: 100px;
    border: 1px dashed #ccc;
    line-height: 100px;
    border-radius: 4px;
    font-size: 30px;
    color: #666;
}
</style>